<template>
  <div>
          <S1 :prop="prop" :imgsrc="imgsrc"  />
          <S2 :prop="prop" :imgsrc="imgsrc" />

  </div>
</template>

<script>
export default {
props:['prop', 'imgsrc'],
 components: {
    S1: () => import("../components/project/s1.vue"),
    S2: () => import("../components/project/s2.vue"),

  },
}
</script>

<style>

</style>